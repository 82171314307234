import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
  plugins:[createPersistedState()],
  state: {
    com_message:'',
    site_id:2
  },

  mutations: {
    modify(state,playload) {
      state.com_message = playload
    },
    xiugai(state,numb) {
      state.site_id = numb
    }
  },
  actions: {
    message(context,playload) {
      context.commit('modify',playload)
    },
    seteng(context,num) {
      context.commit('xiugai', num)
      console.log(99999);
    }
  }
})
